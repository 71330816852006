export default {
  posts: [],
  rssUrl: "",
  maxCols: 2,
  colClass: "col-sm-6",
  layoutStyle: "grid",
  postRows: [],
  currentPost: null,
  postStyle: "modal",
  buttonClass: "",
  readMore: "Read more",
  offset: 0
};
